// 评估流程
<template>
  <div class="service-assess">
    <Info class="assess-info" />
  </div>
</template>

<script>
import Info from '@/components/Info'
export default {
  name: 'Assess',
  components: {
    Info
  },
};
</script>

<style scoped lang="less">
.profile-title {
  p {
    font-size: 21px;
    font-weight: bold;
    color: #333333;
    span {
      font-size: 49px;
      color: #d73a2d;
    }
  }
}
.profile-info {
  margin-top: 36px;
  padding: 50px;
  /deep/ p {
    line-height: 33px;
    span {
      font-size: 18px !important;
    }
  }
}
</style>
